// component to sync the router state with the redux store
import { useEffect, useRef } from "react";
import { useLocation, useNavigationType } from "react-router";
import { useDispatch } from "react-redux";
import { updateLocation } from "./redux/routerSlice";

export function RouterSync() {
  const location = useLocation();
  const navigationType = useNavigationType(); // 'PUSH', 'POP', or 'REPLACE'
  const dispatch = useDispatch();

  // Keep track of the previous location to handle back/forward navigation
  const prevLocationRef = useRef(location);

  useEffect(() => {
    // Only update if location actually changed
    if (
      prevLocationRef.current.pathname !== location.pathname ||
      prevLocationRef.current.search !== location.search ||
      prevLocationRef.current.hash !== location.hash
    ) {
      dispatch(
        updateLocation({
          location: {
            pathname: location.pathname,
            search: location.search,
            hash: location.hash,
            state: location.state
          },
          action: navigationType
        })
      );
      prevLocationRef.current = location;
    }
  }, [location, navigationType, dispatch]);

  return null;
}
