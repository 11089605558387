// Dependencies
import React, { useMemo } from "react";
import clsx from "clsx";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import {
  FAB_STATUS,
  FAB_TYPE,
  mentorFabClicked,
  setFabViewStyle
} from "../../redux/fabSlice";

import { selectSubmission, selectTask } from "../../redux/tasksSlice";
import { CHAT, FAB_STYLE_VIEW } from "../../consts";
// Components

// Material UI
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import { Button, Fab, Zoom, Box, Typography } from "@mui/material";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { FormattedMessage } from "react-intl";
import { useQuery } from "../../hooks";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    fab: { position: "absolute", zIndex: 2 },
    fabExpanded: {
      maxWidth: "250px",
      minHeight: "250px"
    },
    iconContainer: {
      display: "flex",
      opacity: 0.7,
      color: theme.palette.lightText.main
    },
    lableContainer: {
      marginInlineStart: theme.spacing(1),
      height: "fit-content",
      textTransform: "none",
      color: theme.palette.lightText.main
    },
    minimizedLableContainer: {
      width: "fit-content",
      display: "flex",
      flexFlow: "row nowrap",
      textTransform: "none"
    },
    large: { bottom: 24, right: 24 },
    medium: { bottom: 28, right: 28 },
    small: { bottom: 32, right: 32 },
    fabLabel: {
      display: "flex"
    },
    fabButtons: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBlockStart: theme.spacing(2)
    },
    fabBtnYes: {
      color: theme.palette.lightText.main,
      padding: "5px 15px",
      border: `1px solid ${theme.palette.lightText.main}`,
      marginInlineEnd: theme.spacing(2),
      borderRadius: "5px"
    },
    fabBtnNo: { color: theme.palette.lightText.main }
  };
});

export default function CustomFab() {
  // Hooks
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { submission_id, text_id } = useQuery();
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) =>
    selectTask(state, Number(submission.task_id))
  );

  // Redux state
  const fabStatus = useSelector((state) => state.fab.status);
  const fabType = submission_id
    ? FAB_TYPE.MENTOR_REVIEW
    : FAB_TYPE.MENTOR_READER;
  const fabViewStyle = useSelector((state) => state.fab.fabViewStyle);

  const chatSubmissionId = useSelector((state) => state.chat.submission_id);
  const chatTextID = useSelector((state) => state.chat.text_id);

  // Ephemeral state
  // Derived state
  const isVisible = useMemo(() => {
    /**
     * the isVisible is derived from fabStatus + consistent redux state (submission id is the one in chat Slice )
     */
    const status = fabStatus !== FAB_STATUS.HIDDEN;
    return (
      status &&
      (submission?.id || null) == chatSubmissionId &&
      (task?.text_id || text_id) == chatTextID
    );
  }, [fabStatus, submission, task, chatSubmissionId, chatTextID]);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  const fabs = [
    {
      type: FAB_TYPE.MENTOR_REVIEW,
      color: "primary",
      icon: <ChatOutlinedIcon />,
      lable: (
        <FormattedMessage
          id="fab.MENTOR_REVIEW_LABEL"
          defaultMessage="Alethea Coach"
        />
      ),
      ariaLabel: "Chat",
      size: "large",
      callback: () => {
        dispatch(mentorFabClicked({ chatType: CHAT.TYPE.REVIEW }));
      }
    },
    {
      type: FAB_TYPE.MENTOR_READER,
      icon: <ChatOutlinedIcon />,
      color: "secondary",
      lable: (
        <FormattedMessage
          id="fab.MENTOR_READER_LABEL"
          defaultMessage="Alethea Coach"
        />
      ),
      ariaLabel: "Chat",
      size: "large",
      callback: () => {
        dispatch(mentorFabClicked({ chatType: CHAT.TYPE.READER }));
      }
    }
  ];

  // Behavior

  //Render
  return (
    <>
      {fabs.map((fab) => {
        return fab.type === fabType ? (
          <Zoom
            key={fab.color}
            in={isVisible}
            timeout={transitionDuration}
            style={{
              transitionDelay: `${isVisible ? transitionDuration.exit : 0}ms`
            }}
            unmountOnExit>
            <Fab
              tabIndex={-1}
              className={clsx(
                fabViewStyle === FAB_STYLE_VIEW.EXPANDED && classes.fabExpanded,
                classes.fab,
                classes[fab.size]
              )}
              aria-label={fab.ariaLabel}
              color={fab.color}
              size={fab.size}
              variant={fab.lable ? "extended" : "circular"}>
              {fab.lable && fabViewStyle === FAB_STYLE_VIEW.EXPANDED && (
                <Box className={classes.lableContainer}>
                  <Box className={classes.fabLabel}>
                    <Typography variant="p" align="left">
                      {fab.lable}
                    </Typography>
                  </Box>
                  <Box className={classes.fabButtons}>
                    <Button
                      variant="outlined"
                      className={classes.fabBtnYes}
                      onClick={fab.callback}>
                      Yes please
                    </Button>
                    <Button
                      className={classes.fabBtnNo}
                      onClick={() =>
                        dispatch(setFabViewStyle(FAB_STYLE_VIEW.MINIMIZED))
                      }>
                      No thanks
                    </Button>
                  </Box>
                </Box>
              )}
              {fabViewStyle === FAB_STYLE_VIEW.MINIMIZED && (
                <Button
                  onClick={fab.callback}
                  className={classes.minimizedLableContainer}>
                  <Box className={classes.iconContainer}>{fab.icon}</Box>
                  <Typography
                    variant="p"
                    align="right"
                    className={classes.lableContainer}>
                    Alethea Coach
                  </Typography>
                </Button>
              )}
            </Fab>
          </Zoom>
        ) : null;
      })}
    </>
  );
}
