import React, { createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setAuth, clearAuth } from "./redux/firebaseSlice";

const FirebaseAuthContext = createContext(null);

export function FirebaseAuthProvider({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();

    return onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(
          setAuth({
            uid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
            displayName: user.displayName,
            isAnonymous: user.isAnonymous,
            photoURL: user.photoURL,
            providerData: user.providerData,
            stsTokenManager: user.stsTokenManager,
            createdAt: user.metadata.creationTime,
            lastLoginAt: user.metadata.lastSignInTime,
            apiKey: user.auth.config.apiKey,
            appName: user.auth.name
          })
        );
      } else {
        dispatch(clearAuth());
      }
    });
  }, [dispatch]);

  return (
    <FirebaseAuthContext.Provider value={null}>
      {children}
    </FirebaseAuthContext.Provider>
  );
}
