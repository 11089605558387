// Dependencies
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { captureException } from "../../utils/errorHandlers";
import { random } from "lodash-es";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useStorage } from "reactfire";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { addSnackbar } from "../../redux/snackbarSlice";
import { selectIsImpersonation, updateAvatar } from "../../redux/userSlice";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, CircularProgress } from "@mui/material";
import { CustomAvatar } from "../SharedComponents";
import { selectAvatar } from "../../redux/firestoreSelectors";

//Styles
const useStyles = makeStyles((theme) => ({
  avatarSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginBlockStart: theme.spacing(4),
    marginBlockEnd: theme.spacing(6.5),
    borderRadius: 100,
    width: 178,
    height: 178,
    fontSize: "96px"
  },
  root: {
    width: "100%"
  },
  input: {
    display: "none"
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  button: {
    minHeight: 36
  },
  progress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function ProfilePageAvatar() {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const storage = useStorage();
  const inputRef = useRef(null);

  //Ephemeral state
  const [uploading, setUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  //Redux state
  const currentUser = useSelector((state) => state.firebase.auth);
  const avatar = useSelector((state) => selectAvatar(state));
  const isImpersonation = useSelector((state) => selectIsImpersonation(state));

  //Variables
  const USER_UPLOADS = "usersUploads";
  const allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif"
  ];
  const allowedFileSize = 3000000;

  //Behavior
  function uploadAvatar(e, file, currentUser) {
    if (!validateFileType(e, file.type)) return;
    if (!validateFileSize(e, file.size)) return;

    const directory = `${USER_UPLOADS}/${currentUser.uid}`;
    const fileName = `profile_pic_${currentUser.uid}_${random(9999)}`;
    const fileExtension = file.name.split(".").pop();
    const path = `${directory}/${fileName}.${fileExtension}`;

    const metadata = { contentType: file.type };
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploading(true);
        setUploadProgress(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;

          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            handleError(
              e,
              `upload failed, User doesn't have permissions: ${error}`
            );
            break;
          case "storage/canceled":
            // User canceled the upload
            handleError(`upload failed, user canceled the upload: ${error}`);
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            handleError(`upload failed, unknown error: ${error}`);
            break;

          default:
            handleError(`upload failed for unspecified reason: ${error}`);
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          dispatch(updateAvatar(downloadURL));
          setUploading(false);
          setUploadProgress(0);
          clearFileInput(e);
        });
      }
    );
  }

  function handleChange(e) {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      uploadAvatar(e, file, currentUser);
    }
  }

  function handleError(e, message) {
    setUploading(false);
    setUploadProgress(0);
    clearFileInput(e);
    dispatch(
      addSnackbar({
        message: intl.formatMessage({
          id: "error.uploadingAvatarFailed",
          defaultMessage:
            "There was a problem uploading the image, please try again"
        })
      })
    );
    captureException(message);
  }

  function clearFileInput() {
    if (inputRef.current) inputRef.current.value = null;
  }

  function validateFileType(e, type) {
    if (!allowedFileTypes.includes(type)) {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "error.fileTypeNotAllowed",
            defaultMessage: "Please upload an image file"
          })
        })
      );
      clearFileInput(e);
      return false;
    } else return true;
  }

  function validateFileSize(e, size) {
    if (size > allowedFileSize) {
      dispatch(
        addSnackbar({
          message: intl.formatMessage({
            id: "error.exceededSizeLimitation",
            defaultMessage:
              "The uploaded file is to large, please select a smaller file"
          })
        })
      );
      clearFileInput(e);
      return false;
    } else return true;
  }
  // Render
  return (
    <Box component="section" className={classes.avatarSection}>
      <CustomAvatar
        className={classes.avatar}
        id={currentUser.uid}
        name={currentUser.displayName}
        src={avatar}>
        {isImpersonation && <SupervisedUserCircleIcon fontSize="inherit" />}
      </CustomAvatar>
      <Box className={classes.root}>
        <input
          ref={inputRef}
          accept="image/*"
          className={classes.input}
          id="upload-button"
          multiple
          type="file"
          onChange={handleChange}
        />
        <label className={classes.wrapper} htmlFor="upload-button">
          <Button
            fullWidth
            variant="outlined"
            component="span"
            disabled={uploading}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                document.getElementById("upload-button").click();
              }
            }}
            className={classes.button}>
            {!uploading &&
              intl.formatMessage({
                id: "profilePage.addProfilePicture",
                defaultMessage: "Add profile picture"
              })}
          </Button>
          {uploading && (
            <CircularProgress
              value={uploadProgress}
              size={24}
              className={classes.progress}
            />
          )}
        </label>
      </Box>
    </Box>
  );
}
