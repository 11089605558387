import React from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { httpCallables } from "./firebase";
import {
  removeSnackbar,
  addSnackbarUndoActions,
  removeSnackbarUndoActions
} from "./redux/snackbarSlice";
import { selectAlertsDuration } from "./redux/firestoreSelectors";
import {
  interactionsAPI,
  grAPI,
  standardTaskAPI,
  tasksAPI,
  commentsAPI,
  peerReviewAPI
} from "./api";
import { addText, undoCreateText } from "./redux/textsSlice";
import { setIsOpen } from "./redux/emailModalSlice.js";

import { SnackbarContent, Snackbar, Button, Box, Alert } from "@mui/material";

export default function Snackbars() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const snackbars = useSelector((state) => state.snackbars.messages);
  const alertsDuration = useSelector((state) => selectAlertsDuration(state));
  const selectedCourseId = useSelector(
    (state) => state.user.userProfile.selectedCourseId
  );
  const selectedTextId = useSelector((state) => state.texts.selectedTextId);

  const parseMessage = (message) => {
    if (Array.isArray(message)) {
      return (
        <Box>
          {message.map((line) => (
            <Box key={line}>{line}</Box>
          ))}
        </Box>
      );
    } else if (message instanceof Object) {
      return intl.formatMessage({
        id: message.id,
        defaultMessage: message.defaultMessage
      });
    } else {
      return message;
    }
  };

  async function handleActionCallback(type, data) {
    switch (type) {
      case "undoDeleteComment": {
        const { comment } = data;
        if (comment.createWithContainer) commentsAPI.createThread(comment);
        else commentsAPI.createComment(comment);
        break;
      }
      case "undoDeleteText": {
        let { data } = data;
        data = { ...data, course: data.course_id };
        httpCallables.createCourseText(data).then(({ data }) => {
          const { success } = data;
          if (success) {
            const { text } = JSON.parse(data.payload);
            dispatch(addText(text));
          }
        });
        break;
      }
      case "undoDeleteTask":
        tasksAPI.undoDeleteTask(data.task, data.interactions, data.submissions);
        break;
      case "undoPublishTask":
        tasksAPI.undoPublishTask(data.taskId, data.offline_task_id);
        break;
      case "undoStandardTaskSubmission":
        standardTaskAPI.undoStandardTaskSubmission(
          data.submission_id,
          data.offline_task_id
        );
        break;
      case "undoTaskSubmission":
        standardTaskAPI.undoTaskSubmission(
          data.submission_id,
          data.offline_task_id
        );
        break;
      case "undoReflectionSubmission":
        peerReviewAPI.undoReflectionSubmission(data.submission_id);
        break;
      case "undoSubmitGr":
        grAPI.undoGrTaskSubmission(data.submission_id);
        if (data.text_id && data.task_id) {
          navigate(`/task?submission_id=${data.submission_id}`);
        }
        break;
      case "goToReader":
        navigate(`/reader?text_id=${data.text_id}`);
        break;
      case "createHighlight":
        interactionsAPI.createHighlight(
          data.highlight,
          selectedTextId,
          selectedCourseId
        );
        break;
      case "undoDueDateChangeForAllStudents": {
        const { task, prev_due_date } = data;
        // instead of const { task_id, prev_due_date } = queueItem;
        //Doesnt let prev_due_date to be redclared in same scope
        tasksAPI.updateDueDateForAllStudents(task, prev_due_date);
        break;
      }
      case "undoDueDateChangeForStudent": {
        const { submission, prev_due_date } = data;
        tasksAPI.updateDueDateForStudent(submission, prev_due_date);
        break;
      }
      case "createQuestion": {
        const resp = await interactionsAPI.createQuestion(
          data.question,
          selectedTextId,
          selectedCourseId,
          {
            highlights: data.highlights,
            summary: data.summary
          }
        );
        if (resp) {
          grAPI.updateSelectedQuestionId(data.question.id);
        }
        break;
      }
      case "undoUploadText": {
        const { text, offline_task_id } = data;
        dispatch(undoCreateText({ text, offline_task_id }));
        break;
      }
      case "showIntroEmailModal": {
        dispatch(setIsOpen(true));
        break;
      }
      case "undoShareCourseMaterial": {
        const { itemId } = data;
        httpCallables.coursesFunctions({
          func_name: "removeCourseMaterialFromFirestore",
          itemId
        });
        break;
      }
      default:
        break;
    }
  }

  const renderActions = (snackbar) => {
    if (snackbar.actions) {
      return snackbar.actions.map((action) => (
        <Button
          key={`${snackbar.id}+${action.intlDefaultMsg}`}
          onClick={() => {
            handleActionCallback(action.callBack, snackbar.data);
            dispatch(removeSnackbar(snackbar.id));
          }}
          sx={{ textTransform: "uppercase", color: "snackbar.button" }}
          size="small">
          {intl.formatMessage({
            id: action.intlId,
            defaultMessage: action.intlDefaultMsg
          })}
        </Button>
      ));
    } else if (snackbar.undoData) {
      return (
        <Button
          onClick={() => {
            snackbar.undoData &&
              dispatch(addSnackbarUndoActions(snackbar.undoData));
            dispatch(removeSnackbar(snackbar.id));
          }}
          sx={{
            textTransform: "uppercase",
            color: "snackbar.button"
          }}
          size="small">
          {intl.formatMessage({
            id: "undo",
            defaultMessage: "undo"
          })}
        </Button>
      );
    }
  };

  const renderSnackbar = (snackbar, index) => {
    return (
      <Snackbar
        key={snackbar.id}
        open={Boolean(snackbar)}
        autoHideDuration={parseInt(alertsDuration)}
        onClose={() => {
          dispatch(removeSnackbar(snackbar.id));
          snackbar.undoData &&
            dispatch(removeSnackbarUndoActions(snackbar.undoData));
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        sx={{
          position: "static",
          marginBottom: index !== snackbar.length - 1 ? "16px" : 0,
          display: "block",
          "& .MuiSnackbar-root": {
            position: "static"
          },
          "& .MuiPaper-root": {
            marginBottom: 0
          }
        }}
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: "onTouchStart"
        }}>
        {snackbar.severity ? (
          <Alert severity={snackbar.severity}>
            {parseMessage(snackbar.message)}
          </Alert>
        ) : (
          <SnackbarContent
            message={parseMessage(snackbar.message)}
            sx={{ color: "snackbar.text" }}
            action={renderActions(snackbar)}
          />
        )}
      </Snackbar>
    );
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: "16px",
        right: "24px",
        left: "auto"
      }}>
      {snackbars &&
        snackbars.map((snackbar, index) => renderSnackbar(snackbar, index))}
    </Box>
  );
}
