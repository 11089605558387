import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import useCreatePortal from "../../../hooks/useCreatePortal.js";
import FeedbackSwitcher from "./FeedbackSwitcher.jsx";
import useQuery from "../../../hooks/useQuery.js";
import { useDispatch, useSelector } from "react-redux";
import { selectSubmission, selectTask } from "../../../redux/tasksSlice.js";
import { selectCourseByTaskId } from "../../../redux/coursesSlice.js";
import { submitFeedback } from "../../../redux/taskSlice.js";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
  barBtn: {
    marginTop: "0 !important"
  }
}));

export default function FeedbackControls() {
  const classes = useStyles();
  const { submission_id } = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const ActionButtonPortal = useCreatePortal(
    document && document.getElementById("global-action-btn")
  );

  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );
  const task = useSelector((state) => selectTask(state, submission.task_id));
  const course = useSelector((state) =>
    selectCourseByTaskId(state, submission.task_id)
  );
  const userId = useSelector((state) => state.user.auth.uid);

  const isTeacher = course.course_role === "Teacher";
  const isChecked = submission.is_checked;
  const isCheckedReadOnly =
    submission.status === "Graded" && submission.grader !== userId;
  const showControls =
    pathname === "/task" &&
    ((isTeacher && submission.status === "Submitted") ||
      submission.status === "Graded");

  return showControls ? (
    <ActionButtonPortal>
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        {isTeacher && (
          <FeedbackSwitcher task={task} currentSubmission={submission} />
        )}

        {isCheckedReadOnly ? (
          <Button
            className={classes.barBtn}
            onClick={() => {
              isTeacher
                ? navigate(`/tasks?course_id=${course.id}&task_id=${task.id}`)
                : navigate(`/tasks?course_id=${course.id}`);
            }}
            data-testid="submit-task-button"
            size="small">
            <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
          </Button>
        ) : isChecked ? (
          <Box id="feedback-btn">
            <Button
              className={classes.barBtn}
              onClick={() => dispatch(submitFeedback(true))}
              data-testid="submit-task-button"
              size="small">
              <FormattedMessage
                id="feedback.updateFeedback"
                defaultMessage="Update feedback"
              />
            </Button>
          </Box>
        ) : (
          <Box id="feedback-btn">
            <Button
              className={classes.barBtn}
              onClick={() => dispatch(submitFeedback(true))}
              data-testid="submit-task-button"
              size="small">
              <FormattedMessage
                id="feedback.sendFeedback"
                defaultMessage="Send feedback"
              />
            </Button>
          </Box>
        )}
      </Box>
    </ActionButtonPortal>
  ) : null;
}
