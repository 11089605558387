// AuthenticatorMFASetup.jsx
import React, { useState } from "react";
import { multiFactor, TotpMultiFactorGenerator } from "firebase/auth";
import { auth, environment } from "../../../firebase";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { useIntl } from "react-intl";
import { QRCodeSVG } from "qrcode.react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  verificationSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(12), // Increase bottom margin to ensure button is visible
    paddingBottom: theme.spacing(8) // Add padding at the bottom
  },
  qrContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  qrCode: {
    margin: theme.spacing(2)
  },
  secretKeyContainer: {
    width: "100%",
    maxWidth: "100%",
    overflowX: "auto",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  secretKey: {
    fontFamily: "monospace",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    whiteSpace: "nowrap",
    display: "inline-block",
    minWidth: "fit-content"
  }
}));

export default function AuthenticatorMFASetup({ onError, onSuccess }) {
  const classes = useStyles();
  const intl = useIntl();

  // Component state
  const [totpSecret, setTotpSecret] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [totpVerificationCode, setTotpVerificationCode] = useState("");
  const [totpEnrollmentInfo, setTotpEnrollmentInfo] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [loading, setLoading] = useState(false);

  // Helper function to generate QR code URL
  const generateTotpQrCodeUrl = (secret, account, issuer) => {
    const otpauthUrl = `otpauth://totp/${encodeURIComponent(`${issuer}:${account}`)}?secret=${secret}&issuer=${encodeURIComponent(issuer)}&algorithm=SHA1&digits=6&period=30`;
    return otpauthUrl;
  };

  // Generate TOTP secret and QR code
  const generateTotpSecret = async () => {
    setLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      const mfaUser = multiFactor(user);
      const session = await mfaUser.getSession();

      // Generate a TOTP secret
      const enrollmentInfo =
        await TotpMultiFactorGenerator.generateSecret(session);
      setTotpEnrollmentInfo(enrollmentInfo);

      // Get the secret key as a string
      const secretKey = enrollmentInfo.secretKey;
      setTotpSecret(secretKey);

      // Create the QR code URL
      const appName = "Alethea-" + environment + "-"; // Change this to your application name
      const accountIdentifier = user.email || user.phoneNumber || user.uid;
      const qrUrl = generateTotpQrCodeUrl(
        secretKey,
        accountIdentifier,
        appName
      );

      setQrCodeUrl(qrUrl);
      setShowQrCode(true);
      setLoading(false);
    } catch (error) {
      console.error("Error generating TOTP secret:", error);
      onError(error.message || "Failed to generate authenticator app secret");
      setLoading(false);
    }
  };

  // Enroll TOTP MFA
  const enrollTotpMfa = async () => {
    setLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      if (!totpEnrollmentInfo) {
        throw new Error("No TOTP enrollment info available");
      }

      const mfaUser = multiFactor(user);

      // Create the TOTP assertion with the verification code from the authenticator app
      const credential = TotpMultiFactorGenerator.assertionForEnrollment(
        totpEnrollmentInfo,
        totpVerificationCode
      );

      // Enroll the TOTP as a second factor
      await mfaUser.enroll(credential, "Authenticator App");

      // Signal success to parent component
      onSuccess();
    } catch (error) {
      console.error("Error enrolling TOTP MFA:", error);
      onError(
        error.message ||
          "Failed to verify code. Please ensure you entered the correct code from your authenticator app."
      );
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100% - 100px)",
        maxHeight: "calc(100% - 100px)",
        overflowY: "auto"
      }}>
      {!showQrCode ? (
        <>
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({
              id: "mfa.setup.authenticator.description",
              defaultMessage:
                "Set up an authenticator app like Google Authenticator, Microsoft Authenticator, or Authy to generate verification codes."
            })}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            onClick={generateTotpSecret}
            className={classes.button}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              intl.formatMessage({
                id: "mfa.setup.authenticator.generate",
                defaultMessage: "Generate QR Code"
              })
            )}
          </Button>
        </>
      ) : (
        <Box className={classes.verificationSection}>
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({
              id: "mfa.setup.authenticator.scan",
              defaultMessage: "Scan this QR code with your authenticator app."
            })}
          </Typography>

          <Box className={classes.qrContainer}>
            <QRCodeSVG
              value={qrCodeUrl}
              size={200}
              className={classes.qrCode}
            />

            <Typography variant="body2" gutterBottom>
              {intl.formatMessage({
                id: "mfa.setup.authenticator.manualEntry",
                defaultMessage: "Or enter this key manually:"
              })}
            </Typography>

            <Box
              className={classes.secretKey}
              width="100%"
              overflow="auto"
              sx={{ mt: 4, mb: 10, pb: 6 }}>
              <Typography variant="body1">{totpSecret}</Typography>
            </Box>
          </Box>

          <TextField
            label={intl.formatMessage({
              id: "mfa.setup.authenticator.code",
              defaultMessage: "Verification Code from Authenticator App"
            })}
            value={totpVerificationCode}
            onChange={(e) => setTotpVerificationCode(e.target.value)}
            fullWidth
            className={classes.formField}
            variant="outlined"
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || totpVerificationCode.length < 6}
            onClick={enrollTotpMfa}
            className={classes.button}
            sx={{ mt: 2, mb: 8 }}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              intl.formatMessage({
                id: "mfa.setup.completeSetup",
                defaultMessage: "Complete MFA Setup"
              })
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
}
