// Dependecies
import React, { useState, useRef, useMemo } from "react";
import { useNavigate, Link as RouterLink } from "react-router";
import clsx from "clsx";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumbsClicked } from "../../redux/readerActionsSlice";

// hooks
import { useResizeObserver } from "../../hooks/useResizeObserver";
import useWindowSize from "../../hooks/useWindowSize";

// Components
import DirectionalChevronIcon from "../SharedComponents/DirectionalChevronIcon";
import TooltipWithIntl from "../SharedComponents/tooltip/TooltipWithIntl";

// Mui
import makeStyles from "@mui/styles/makeStyles";

import {
  Breadcrumbs,
  Link,
  Menu,
  MenuItem,
  IconButton,
  Box
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const useStyles = makeStyles((theme) => {
  return {
    chivo: {
      fontFamily: theme.typography.fontFamily
    },
    pointer: {
      cursor: "pointer",
      display: "flex",
      flexFlow: "row"
    },
    menu: {
      padding: 0,
      color: "white",
      "&.Mui-disabled": {
        color: "white",
        opacity: 0.2
      }
    },
    breadcrumbs: {
      height: "56px",
      display: "flex",
      flexFlow: "row",
      scrollbarWidth: "0px",
      scrollbarHeight: "0px",
      color: "#FFFFFF",
      backgroundImage: "none",
      "_& ol": {
        height: "100%",
        flexWrap: "nowrap"
      },
      get "& ol"() {
        return this["_& ol"];
      },
      set "& ol"(value) {
        this["_& ol"] = value;
      },
      "&::-webkit-scrollbar": {
        width: "0",
        height: "0" /* Remove scrollbar space */,
        background: "transparent" /* Optional: just make scrollbar invisible */
      },
      whiteSpace: "nowrap",
      width: "auto",
      overflow: "hidden",
      marginInlineEnd: theme.spacing(2)
    }
  };
});

export default function AletheaBreadcrumbs({ headerMenuRef }) {
  //Redux
  const allBreadcrumbs = useSelector(
    (state) => state.readerActions.breadcrumbs
  );
  const breadcrumbs = (
    Array.isArray(allBreadcrumbs) ? allBreadcrumbs : []
  )?.filter((a) => a?.text);
  const dispatch = useDispatch();

  //Hooks
  const breadcrumbsRef = useRef(null);
  const measureRef = useRef(null);

  const navigate = useNavigate();

  const { width: breadcrumbsWidth } = useResizeObserver(breadcrumbsRef);
  const windowSize = useWindowSize();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getMenuWidth = () => {
    let rect =
      headerMenuRef &&
      headerMenuRef.current &&
      headerMenuRef.current.getBoundingClientRect();

    return rect && rect.width ? rect.width + 32 : 0;
  };

  // Behavior
  const isCondensed = useMemo(() => {
    if (!breadcrumbs?.length || !measureRef.current) return false;
    if (breadcrumbs.length <= 1) return false;

    const menuWidth = getMenuWidth();
    const availableWidth = windowSize.width - menuWidth;
    const fullWidth = measureRef.current.offsetWidth;
    const ratio = fullWidth / availableWidth;
    //console.log("breadcrumbsWidth", {menuWidth, fullWidth,availableWidth, breadcrumbsWidth,windowSize,ratio});

    return ratio > 0.3;
  }, [breadcrumbs, windowSize.width]);

  const truncateMiddle = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    const ellipsis = "...";
    const snippetLength = Math.floor((maxLength - ellipsis.length) / 2);
    const snippetStart = text.slice(0, snippetLength);
    const snippetEnd = text.slice(-snippetLength);
    return (
      <TooltipWithIntl
        intlStringId={text}
        defaultMessage={text}
        placement={"bottom"}>
        {snippetStart}
        {ellipsis}
        {snippetEnd}
      </TooltipWithIntl>
    );
  };

  const handleClick = (event) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      event.target.click();
    }
  };

  const ContrastMoreIcon = () => (
    <MoreHorizIcon
      fontSize={"small"}
      sx={{
        color: "#000000",
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "24px",
        height: "16px"
      }}
    />
  );

  // Render
  const renderMenu = (parts) => {
    return (
      <Menu
        id="breadcrumbs-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="breadcrumbs-button"
        MenuListProps={{
          "aria-labelledby": "breadcrumbs-button"
        }}
        // Add these props to improve click-away behavior
        disableScrollLock={true}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}>
        {parts.map((part, index) => {
          return (
            <MenuItem
              key={index}
              onClick={(e) => {
                if (part.url) {
                  navigate(part.url);
                }
                handleClose();
              }}>
              {part.text}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const showCondensed = false; // breadcrumbs?.length > 1 && ratio > 0.3;

  const renderCondensed = () => {
    return (
      <Box>
        <IconButton
          id="breadcrumbs-button"
          aria-controls="breadcrumbs-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.menu}>
          <MoreHorizIcon className={classes.menu} />
        </IconButton>
        {renderMenu(breadcrumbs)}
      </Box>
    );
  };

  const renderPart = (part, i) => {
    return (
      <Link
        key={i}
        color="inherit"
        data-test-class="bradcrumb"
        component={RouterLink}
        to={part.url ? part.url : "#"}
        underline="hover"
        className={clsx(classes.chivo, classes.pointer, classes.breadcrumbItem)}
        onClick={() => {
          dispatch(setBreadcrumbsClicked(part));
        }}
        aria-current={i === breadcrumbs.length - 1 ? "page" : false} // TODO: find better way to check if this is the current page
        onKeyDown={handleKeyDown}>
        {truncateMiddle(part.text, 17)}
      </Link>
    );
  };

  let menuWidth = getMenuWidth();
  let styleObj = { maxWidth: `calc(100vw - ${menuWidth}px)` };

  const renderDummyComponent = () => {
    return (
      <div
        style={{
          position: "absolute",
          visibility: "hidden",
          height: 0,
          overflow: "hidden"
        }}>
        {renderBreadcrumbs(measureRef, false)}
      </div>
    );
  };

  const renderBreadcrumbs = (ref, showCondensed) => {
    return (
      <Breadcrumbs
        ref={ref}
        className={classes.breadcrumbs}
        style={styleObj}
        maxItems={2}
        sx={{
          "& .MuiButtonBase-root": {
            "&:focus-visible": {
              outline: "2px solid white",
              border: "2px solid black"
            }
          }
        }}
        separator={<DirectionalChevronIcon fontSize={"small"} />}
        slots={{
          CollapsedIcon: ContrastMoreIcon
        }}
        aria-label="breadcrumb">
        {showCondensed
          ? renderCondensed()
          : breadcrumbs.map((part, i) => renderPart(part, i))}
      </Breadcrumbs>
    );
  };

  return (
    <>
      {renderDummyComponent()}

      {renderBreadcrumbs(breadcrumbsRef, isCondensed)}
    </>
  );
}
