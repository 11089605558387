// PhoneMFASetup.jsx
import React, { useState, useEffect } from "react";
import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier
} from "firebase/auth";
import { auth } from "../../../firebase";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { useIntl } from "react-intl";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  verificationSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4)
  }
}));

// Create a global recaptcha verifier instance to avoid re-creation issues
let recaptchaVerifierInstance = null;

export default function PhoneMFASetup({ onError, onSuccess }) {
  const classes = useStyles();
  const intl = useIntl();

  // Component state
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Initialize the recaptcha verifier once when the component mounts
  useEffect(() => {
    console.log("Setting up reCAPTCHA...");

    // Function to initialize reCAPTCHA
    const initializeRecaptcha = () => {
      // Clear any existing instance first
      if (recaptchaVerifierInstance) {
        try {
          recaptchaVerifierInstance.clear();
          console.log("Cleared existing reCAPTCHA");
        } catch (error) {
          console.log("Error clearing existing reCAPTCHA:", error);
        }
        recaptchaVerifierInstance = null;
      }

      // Create new instance
      try {
        console.log("Creating new reCAPTCHA verifier instance");
        recaptchaVerifierInstance = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: () => {
              console.log("reCAPTCHA solved successfully");
            },
            "expired-callback": () => {
              console.log("reCAPTCHA expired");
              // Re-initialize on expiry
              initializeRecaptcha();
            }
          }
        );

        console.log("Rendering reCAPTCHA");
        return recaptchaVerifierInstance
          .render()
          .then(() => {
            console.log("reCAPTCHA rendered successfully");
            return true;
          })
          .catch((error) => {
            console.error("Error rendering reCAPTCHA:", error);
            onError(
              "Security verification failed to initialize. Please reload the page."
            );
            return false;
          });
      } catch (error) {
        console.error("Error creating reCAPTCHA verifier:", error);
        onError(
          "Security verification could not be created. Please reload the page."
        );
        return Promise.resolve(false);
      }
    };

    // Initialize
    initializeRecaptcha();

    // Clean up on unmount
    return () => {
      console.log("Cleaning up reCAPTCHA...");
      if (recaptchaVerifierInstance) {
        try {
          recaptchaVerifierInstance.clear();
          recaptchaVerifierInstance = null;
          console.log("reCAPTCHA cleared on unmount");
        } catch (error) {
          console.log("Error clearing reCAPTCHA on unmount:", error);
        }
      }
    };
  }, [onError]);

  const sendVerificationCode = async () => {
    console.log("Sending verification code...");
    setLoading(true);

    try {
      console.log("reCAPTCHA verifier exists:", !!recaptchaVerifierInstance);
      // If verifier doesn't exist, try to create it
      if (!recaptchaVerifierInstance) {
        console.log("No reCAPTCHA verifier found, creating a new one...");

        recaptchaVerifierInstance = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: () => {
              console.log("On-demand reCAPTCHA verification succeeded");
            }
          }
        );

        try {
          await recaptchaVerifierInstance.render();
          console.log("reCAPTCHA rendered on-demand");
        } catch (renderError) {
          console.error("Failed to render reCAPTCHA on-demand:", renderError);
          throw new Error(
            "Could not initialize security verification. Please reload the page."
          );
        }
      }

      // Verify the user is authenticated
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      // Get multi-factor session
      const mfaUser = multiFactor(user);
      const session = await mfaUser.getSession();
      console.log("Got MFA session");

      // Format phone number
      const formattedPhone = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;
      console.log("Using phone number:", formattedPhone);

      // Create phone provider and send verification
      const phoneProvider = new PhoneAuthProvider(auth);
      console.log("Sending verification code with reCAPTCHA...");

      const verId = await phoneProvider.verifyPhoneNumber(
        {
          phoneNumber: formattedPhone,
          session: session
        },
        recaptchaVerifierInstance
      );
      console.log("Verification code sent successfully");

      // Update state
      setVerificationId(verId);
      setCodeSent(true);
      setLoading(false);
    } catch (error) {
      console.error("Error sending verification code:", error);

      let errorMessage = "Failed to send verification code";
      if (error.code === "auth/invalid-phone-number") {
        errorMessage =
          "Invalid phone number format. Please include country code (e.g., +1 for US).";
      } else if (error.code === "auth/quota-exceeded") {
        errorMessage = "SMS quota exceeded. Please try again later.";
      } else if (error.code === "auth/captcha-check-failed") {
        errorMessage = "Security verification failed. Please try again.";
      } else if (error.message) {
        errorMessage = error.message;
      }

      onError(errorMessage);
      setLoading(false);

      // Try to reset reCAPTCHA if there was an error
      if (recaptchaVerifierInstance) {
        try {
          recaptchaVerifierInstance.clear();
          recaptchaVerifierInstance = null;
          console.log("Cleared reCAPTCHA after error");
        } catch (clearError) {
          console.log("Error clearing reCAPTCHA after error:", clearError);
        }
      }
    }
  };

  const enrollPhoneMfa = async () => {
    setLoading(true);

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      const mfaUser = multiFactor(user);

      // Create credential with the verification code
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      // Enroll the second factor
      await mfaUser.enroll(multiFactorAssertion, "Phone Authentication");

      // Signal success to parent component
      onSuccess();
    } catch (error) {
      console.error("Error enrolling MFA:", error);

      let errorMessage = "Failed to enroll MFA";
      if (error.code === "auth/invalid-verification-code") {
        errorMessage = "Invalid verification code. Please try again.";
      } else if (error.message) {
        errorMessage = error.message;
      }

      onError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* Hidden container for reCAPTCHA */}
      <div id="recaptcha-container" style={{ display: "none" }}></div>

      {!codeSent ? (
        <>
          <TextField
            label={intl.formatMessage({
              id: "mfa.setup.phoneNumber",
              defaultMessage:
                "Phone Number (with country code, e.g., +1234567890)"
            })}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            className={classes.formField}
            variant="outlined"
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || !phoneNumber}
            onClick={sendVerificationCode}
            className={classes.button}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              intl.formatMessage({
                id: "mfa.setup.sendCode",
                defaultMessage: "Send Verification Code"
              })
            )}
          </Button>
        </>
      ) : (
        <Box className={classes.verificationSection}>
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({
              id: "mfa.setup.codeSent",
              defaultMessage:
                "Verification code sent to your phone. Enter it below to complete setup."
            })}
          </Typography>

          <TextField
            label={intl.formatMessage({
              id: "mfa.setup.verificationCode",
              defaultMessage: "Verification Code"
            })}
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            fullWidth
            className={classes.formField}
            variant="outlined"
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || !verificationCode}
            onClick={enrollPhoneMfa}
            className={classes.button}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              intl.formatMessage({
                id: "mfa.setup.completeSetup",
                defaultMessage: "Complete MFA Setup"
              })
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
}
