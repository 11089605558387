// MFAVerification.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Paper,
  Tabs,
  Tab
} from "@mui/material";
import { useIntl } from "react-intl";
import makeStyles from "@mui/styles/makeStyles";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
  RecaptchaVerifier
} from "firebase/auth";
import { auth } from "../../../firebase";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  formField: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  },
  tabContent: {
    padding: theme.spacing(2, 0)
  },
  recaptchaContainer: {
    marginBottom: theme.spacing(2)
  }
}));

// resolver is an instance of MultiFactorResolver that's passed in from the MultiFactorError
export default function MFAVerification({ resolver, onComplete }) {
  const classes = useStyles();
  const intl = useIntl();

  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [availableMethods, setAvailableMethods] = useState([]);

  // Reference for the recaptcha container
  const recaptchaContainerRef = useRef(null);
  // Reference for the recaptcha verifier instance
  const recaptchaVerifierRef = useRef(null);

  // Process available MFA methods when component mounts
  useEffect(() => {
    processMfaHints();

    // Cleanup recaptcha when component unmounts
    return () => {
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
      }
    };
  }, []);

  const processMfaHints = () => {
    // Find available MFA methods from resolver hints
    const methods = [];

    resolver.hints.forEach((hint) => {
      if (hint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        methods.push({
          type: "phone",
          hint,
          displayName: hint.displayName || "Phone"
        });
      } else if (hint.factorId === TotpMultiFactorGenerator.FACTOR_ID) {
        methods.push({
          type: "totp",
          hint,
          displayName: hint.displayName || "Authenticator App"
        });
      }
    });

    setAvailableMethods(methods);

    // If we have methods, initiate verification for the first method
    if (methods.length > 0) {
      // Default to first available method
      handleMethodChange(0);
    } else {
      setError("No supported MFA methods found");
    }
  };

  const initializeRecaptcha = () => {
    // Clear any existing recaptcha
    if (recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current.clear();
    }

    // Create a new RecaptchaVerifier instance with invisible reCAPTCHA
    recaptchaVerifierRef.current = new RecaptchaVerifier(
      auth,
      "send-code-button",
      {
        size: "invisible",
        callback: (recaptchaToken) => {
          // This callback may not be reliably called with invisible reCAPTCHA
          // We'll handle the verification flow in the button click handler
          console.log(
            "reCAPTCHA verification completed with token",
            recaptchaToken
          );
        },
        "expired-callback": () => {
          // Reset reCAPTCHA
          if (recaptchaVerifierRef.current) {
            recaptchaVerifierRef.current.clear();
            initializeRecaptcha();
          }
          setError("reCAPTCHA verification expired. Please try again.");
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (availableMethods[selectedTabIndex]?.type === "phone") {
      // Initialize invisible reCAPTCHA for phone verification
      // Use setTimeout to ensure the DOM is ready
      setTimeout(() => {
        initializeRecaptcha();
      }, 300);
    }
  }, [selectedTabIndex, availableMethods]);

  const handleMethodChange = (index) => {
    setSelectedTabIndex(index);
    setVerificationCode("");
    setError("");

    // For TOTP (authenticator app), no need to send anything - user will enter code from app
  };

  const sendPhoneVerificationCode = async (hint) => {
    setError("");

    try {
      // Start the verification process
      const phoneProvider = new PhoneAuthProvider(auth);
      // For MFA verification, we use the session from the resolver and the recaptcha verifier
      const verId = await phoneProvider.verifyPhoneNumber(
        {
          multiFactorHint: hint,
          session: resolver.session
        },
        recaptchaVerifierRef.current
      );

      setVerificationId(verId);
      setLoading(false);

      // Success message
      setError(""); // Clear any previous errors
      return verId;
    } catch (error) {
      console.error("Error sending verification code:", error);
      setError(error.message || "Failed to send verification code");
      setLoading(false);

      // Reset reCAPTCHA on error
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
        initializeRecaptcha();
      }

      throw error; // Re-throw to handle in the calling function
    }
  };

  const resendVerificationCode = () => {
    // For resending, we need to re-initialize reCAPTCHA
    setLoading(true);

    // Reset reCAPTCHA and initialize a new one
    if (recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current.clear();
    }

    // Initialize with a small delay to ensure DOM is ready
    setTimeout(() => {
      initializeRecaptcha();

      // Then manually trigger the verification process
      recaptchaVerifierRef.current
        .verify()
        .then(() => {
          // After successful reCAPTCHA verification, send the code
          return sendPhoneVerificationCode(
            availableMethods[selectedTabIndex].hint
          );
        })
        .catch((error) => {
          console.error("Error during resend verification:", error);
          setError("Failed to resend code. Please try again.");
          setLoading(false);
        });
    }, 500);
  };

  const completeSignIn = async () => {
    setLoading(true);
    setError("");

    try {
      const selectedMethod = availableMethods[selectedTabIndex];
      let multiFactorAssertion;

      if (selectedMethod.type === "phone") {
        // Create credential with the verification code for phone
        const cred = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      } else if (selectedMethod.type === "totp") {
        multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
          resolver.hints[0].uid,
          verificationCode
        );
      } else {
        throw new Error("Unsupported authentication method");
      }

      // Complete sign in
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

      // Call the onComplete callback with user credentials
      if (onComplete) {
        onComplete(userCredential);
      }
    } catch (error) {
      console.error("Error completing MFA:", error);
      setError(error.message || "Failed to complete authentication");
      setLoading(false);
    }
  };

  const renderPhoneVerification = () => (
    <div className={classes.tabContent}>
      <Typography variant="body1" gutterBottom>
        {intl.formatMessage({
          id: "mfa.verification.phone.description",
          defaultMessage:
            "Click the button below to send a verification code to your phone."
        })}
      </Typography>

      {/* For invisible reCAPTCHA, we don't need a visible container */}
      <div ref={recaptchaContainerRef} style={{ display: "none" }}></div>

      <Button
        id="send-code-button"
        variant="contained"
        color="secondary"
        fullWidth
        disabled={loading}
        onClick={() => {
          setLoading(true);

          // With invisible reCAPTCHA, we need to handle the flow manually
          // First verify reCAPTCHA, then send verification code directly
          recaptchaVerifierRef.current
            .verify()
            .then(() => {
              // After successful reCAPTCHA verification, send the code
              return sendPhoneVerificationCode(
                availableMethods[selectedTabIndex].hint
              );
            })
            .catch((error) => {
              console.error(
                "reCAPTCHA verification or sending code failed:",
                error
              );
              setError(
                "Failed to verify reCAPTCHA or send code. Please try again."
              );
              setLoading(false);
            });
        }}
        className={classes.button}>
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          intl.formatMessage({
            id: "mfa.verification.send",
            defaultMessage: "Send Verification Code"
          })
        )}
      </Button>

      <TextField
        label={intl.formatMessage({
          id: "mfa.verification.code",
          defaultMessage: "Verification Code"
        })}
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        fullWidth
        className={classes.formField}
        variant="outlined"
        style={{ marginTop: "16px" }}
      />

      <Button
        variant="text"
        color="primary"
        fullWidth
        disabled={loading}
        onClick={resendVerificationCode}
        className={classes.button}>
        {intl.formatMessage({
          id: "mfa.verification.resend",
          defaultMessage: "Resend Code"
        })}
      </Button>
    </div>
  );

  const renderTotpVerification = () => (
    <div className={classes.tabContent}>
      <Typography variant="body1" gutterBottom>
        {intl.formatMessage({
          id: "mfa.verification.totp.description",
          defaultMessage:
            "Enter the verification code from your authenticator app to complete sign-in."
        })}
      </Typography>

      <TextField
        label={intl.formatMessage({
          id: "mfa.verification.code",
          defaultMessage: "Verification Code"
        })}
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        fullWidth
        className={classes.formField}
        variant="outlined"
      />
    </div>
  );

  return (
    <Paper elevation={3} className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {intl.formatMessage({
          id: "mfa.verification.title",
          defaultMessage: "Two-Factor Authentication Required"
        })}
      </Typography>

      {availableMethods.length > 1 && (
        <Tabs
          value={selectedTabIndex}
          onChange={(e, newValue) => handleMethodChange(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="MFA methods"
          sx={{ mb: 2 }}>
          {availableMethods.map((method, index) => (
            <Tab key={index} label={method.displayName} />
          ))}
        </Tabs>
      )}

      {availableMethods.length > 0 && (
        <>
          {availableMethods[selectedTabIndex]?.type === "phone"
            ? renderPhoneVerification()
            : renderTotpVerification()}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || !verificationCode}
            onClick={completeSignIn}
            className={classes.button}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              intl.formatMessage({
                id: "mfa.verification.verify",
                defaultMessage: "Verify and Sign In"
              })
            )}
          </Button>
        </>
      )}

      {error && (
        <Typography color="error" style={{ marginTop: "16px" }}>
          {error}
        </Typography>
      )}
    </Paper>
  );
}
