import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Chip } from "@mui/material";
import {
  setAnnotatorMode,
  setDetachedCommentMode
} from "../../redux/readerActionsSlice";
import { setSelectedInteractionId } from "../../redux/interactionsSlice";
import { setSelectedQuestion } from "../../redux/grSlice";
import { COMMENT_PANEL_VIEW } from "../../consts";
import { setCommentPanelState } from "../../redux/realtimeInteractionsSlice";
import { setCustomCursor } from "../../redux/layoutSlice";

const emptyObject = {};
const useStyles = makeStyles((theme) => ({
  cursorText: {
    position: "fixed",
    fontSize: "13px",
    pointerEvents: "none",
    userSelect: "none",
    zIndex: 9999
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.lightText.main
  }
}));

const CustomCursor = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const isCustomCursorEnabled = useSelector(
    (state) => state.layout.isTextCursor
  );
  const action = useSelector(
    (state) => state.readerActions.persistentActionState.actionBar
  );
  const detachedComment = useSelector(
    (state) => state.readerActions.detachedComment
  );
  const activeQuestion = useSelector(
    (state) => state.interactions.questions[0] || emptyObject
  );
  useEffect(() => {
    const onMouseMove = (event) => {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    };
    const onKeyDown = (e) => {
      // if Escape is pressed, revert the text and exit
      if (e.key === "Escape") {
        if (["poc", ""].includes(action)) dispatch(setAnnotatorMode(action));
        else dispatch(setAnnotatorMode("highlight"));
        dispatch(setCommentPanelState(COMMENT_PANEL_VIEW.ALL_THREADS));
        if (activeQuestion?.id) {
          dispatch(setSelectedInteractionId(activeQuestion.id));
          dispatch(setSelectedQuestion(activeQuestion.id));
        }
        dispatch(setCustomCursor(false));
        dispatch(setDetachedCommentMode(false));
      }
    };
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <>
      {isCustomCursorEnabled && (
        <div
          className={classes.cursorText}
          style={{ left: cursorPosition.x + 10, top: cursorPosition.y - 10 }}>
          <Chip
            className={classes.chip}
            label="Select Text"
            size="small"
            clickable={false}
          />
        </div>
      )}
      <style>
        {`
          * {
            cursor: ${detachedComment ?? "text"} !important;
          }
        `}
      </style>
    </>
  );
};

export default CustomCursor;
