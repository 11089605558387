import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { selectCourseRole } from "../../redux/coursesSlice";
import { useQuery } from "../../hooks";
import { selectSubmission } from "../../redux/tasksSlice";
import { TASK } from "../../consts";

const PATHS = {
  NEW_TASK: "/tasks/new",
  TASKS: "/tasks",
  TASK: "/task",
  READER: "/reader",
  SPECIFIC_PATHS: ["/task", "/reader"]
};

const TASK_TYPES = {
  STANDARD: "standard",
  GUIDED_READING: "guidedReading"
};

const getLabelsByTaskType = (
  currentRole,
  taskType,
  step,
  stepByLocation,
  studentFaqLabel,
  labels
) => {
  if (taskType === TASK_TYPES.STANDARD && step === "highlight") {
    return [
      ...studentFaqLabel,
      `${currentRole}::${taskType}::step::${stepByLocation}`,
      ...labels
    ];
  }

  if (taskType === TASK_TYPES.GUIDED_READING && step === "highlight") {
    return [
      ...studentFaqLabel,
      `${currentRole}::gr::step::${stepByLocation}`,
      ...labels
    ];
  }

  return null;
};

export const ZendeskLabels = ({ searchTerm = "", labels = [] }) => {
  const { pathname } = useLocation();
  const { submission_id } = useQuery();
  const currentRole = useSelector(selectCourseRole)?.toLowerCase() || "";
  const selectedTaskId = useSelector((state) => state.tasks.selectedTaskId);
  const tasks = useSelector((state) => state.tasks.tasks);
  const step = useSelector((state) => state.task.step);
  const grStep = useSelector((state) => state.gr.stage);
  const submission = useSelector((state) =>
    selectSubmission(state, Number(submission_id))
  );

  const studentFaqLabel = useMemo(
    () => (currentRole === "student" ? ["student::faq"] : []),
    [currentRole]
  );

  const isSubmittedTeacherTask = useMemo(
    () =>
      submission?.status === TASK.SUBMISSION_STATUS.SUBMITTED &&
      currentRole === "teacher" &&
      pathname === PATHS.TASK,
    [submission?.status, currentRole, pathname]
  );

  const isSubmittedStudentTask = useMemo(
    () =>
      submission?.status === TASK.SUBMISSION_STATUS.GRADED &&
      currentRole === "student" &&
      pathname === PATHS.TASK,
    [submission?.status, currentRole, pathname]
  );

  const zendeskLabels = useMemo(() => {
    let baseLabel = pathname.replace(/\//g, "::");

    // Early returns for special cases
    if (pathname === PATHS.NEW_TASK) {
      return [...studentFaqLabel, "new::task", ...labels];
    }

    if (pathname === PATHS.TASKS) {
      return [...studentFaqLabel, `${currentRole}${baseLabel}`, ...labels];
    }

    if (isSubmittedTeacherTask) {
      return ["teacher::submitted::tasks", ...labels];
    }
    if (isSubmittedStudentTask) {
      return ["student::submitted::tasks", ...labels];
    }

    // Handle specific paths
    if (PATHS.SPECIFIC_PATHS.includes(pathname)) {
      const stepByLocation = pathname === PATHS.READER ? grStep : step;

      if (selectedTaskId) {
        const filteredTask = tasks.find(
          (task) => task.id === Number(selectedTaskId)
        );

        if (filteredTask?.task_type) {
          const taskTypeLabels = getLabelsByTaskType(
            currentRole,
            filteredTask.task_type,
            step,
            stepByLocation,
            studentFaqLabel,
            labels
          );

          if (taskTypeLabels) {
            return taskTypeLabels;
          }
        }
      }

      return [
        ...studentFaqLabel,
        `${currentRole}${baseLabel}::step::${stepByLocation}`,
        ...labels
      ];
    }

    // Default return
    return [...studentFaqLabel, `${currentRole}${baseLabel}`, ...labels];
  }, [
    pathname,
    labels,
    currentRole,
    step,
    grStep,
    tasks,
    selectedTaskId,
    studentFaqLabel,
    isSubmittedTeacherTask
  ]);

  useEffect(() => {
    if (window?.zE) {
      window.zE("webWidget", "helpCenter:setSuggestions", {
        url: false,
        search: searchTerm || true,
        labels: zendeskLabels
      });
    }
  }, [zendeskLabels, searchTerm]);

  return null;
};
