import React from "react";
import { useIntl } from "react-intl";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { changeLocale } from "../../redux/userSlice";
import { selectLocale } from "../../redux/firestoreSelectors";
import { LOCALES } from "../../consts";
import makeStyles from "@mui/styles/makeStyles";
import { MenuItem, TextField } from "@mui/material";

//Styles
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%"
  }
}));

export default function LanguageSelect() {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  //Ephemeral state

  //Redux state
  const locale = useSelector((state) => selectLocale(state));

  //Derived state
  const localesArray = Object.values(LOCALES);

  //Behavior

  // Render
  return (
    <TextField
      fullWidth
      id="language-select"
      label={intl.formatMessage({
        id: "profilePage.language",
        defaultMessage: "Language"
      })}
      value={locale}
      select
      onChange={(e) => {
        const newLocale = localesArray.find(
          (locale) => locale.tag === e.target.value
        );
        dispatch(changeLocale(newLocale));
      }}
      variant="outlined"
      // language selecting is disabled for now
      disabled
      slotProps={{
        select: { labelId: "language-select-lable" }
      }}>
      {localesArray.map((locale) => {
        return (
          <MenuItem key={locale.tag} value={locale.tag}>
            {locale.language}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
