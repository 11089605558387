// ReauthenticationForm.jsx
import React, { useState } from "react";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  reauthenticateWithPopup,
  GoogleAuthProvider
} from "firebase/auth";
import { auth } from "../../../firebase";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { useIntl } from "react-intl";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3)
  },
  formField: {
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

export default function ReauthenticationForm({
  onSuccess,
  onError,
  loading,
  setLoading
}) {
  const classes = useStyles();
  const intl = useIntl();

  // Component state
  const [password, setPassword] = useState("");
  const [reauthError, setReauthError] = useState("");

  // Get the current user's auth provider
  const currentProvider = auth.currentUser?.providerData[0]?.providerId || "";
  const isEmailProvider = currentProvider === "password";
  const isGoogleProvider = currentProvider === "google.com";

  const handleReauthentication = async () => {
    setLoading(true);
    setReauthError("");

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      if (isEmailProvider) {
        // Email/password authentication
        if (!password) {
          setReauthError("Password is required");
          setLoading(false);
          return;
        }

        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);
      } else if (isGoogleProvider) {
        // Google authentication
        const provider = new GoogleAuthProvider();
        await reauthenticateWithPopup(user, provider);
      } else {
        throw new Error(`Unsupported provider: ${currentProvider}`);
      }

      // If we get here, reauthentication was successful
      setLoading(false);
      onSuccess();
    } catch (error) {
      console.error("Reauthentication failed:", error);
      setReauthError(error.message || "Reauthentication failed");
      setLoading(false);
      onError(error.message);
    }
  };

  return (
    <Box>
      <Typography variant="h5" className={classes.title}>
        {intl.formatMessage({
          id: "mfa.reauth.title",
          defaultMessage: "Reauthentication Required"
        })}
      </Typography>

      <Typography variant="body1" gutterBottom>
        {intl.formatMessage({
          id: "mfa.reauth.description",
          defaultMessage:
            "For security reasons, you need to verify your identity again before setting up MFA."
        })}
      </Typography>

      {isEmailProvider && (
        <TextField
          label={intl.formatMessage({
            id: "mfa.reauth.password",
            defaultMessage: "Your Password"
          })}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          className={classes.formField}
          variant="outlined"
        />
      )}

      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading || (isEmailProvider && !password)}
        onClick={handleReauthentication}
        className={classes.button}>
        {loading ? (
          <CircularProgress size={24} />
        ) : isGoogleProvider ? (
          intl.formatMessage({
            id: "mfa.reauth.googleButton",
            defaultMessage: "Reauthenticate with Google"
          })
        ) : (
          intl.formatMessage({
            id: "mfa.reauth.submitButton",
            defaultMessage: "Verify Identity"
          })
        )}
      </Button>

      {reauthError && (
        <Typography color="error" style={{ marginTop: "16px" }}>
          {reauthError}
        </Typography>
      )}
    </Box>
  );
}
