// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { httpCallables, storage, getRecaptchaKey } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router";
import * as Sentry from "@sentry/browser";
// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../redux/readerActionsSlice";
import { addSnackbar } from "../../redux/snackbarSlice";

import makeStyles from "@mui/styles/makeStyles";
import { Box, TextField, Typography, Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgressWithLabel, truncateMiddle } from "./utils";
import ReCAPTCHA from "react-google-recaptcha";
import { ScrollBox } from "../SharedComponents";
import NewTextEditor from "../SharedComponents/textEditor/NewTextEditor";

//Styles
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex"
  },
  mainView: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-start"
  },
  inner: {
    width: "100%",
    height: "100%",
    alignItems: "flex-end"
  },
  form: {
    flexFlow: "column nowrap",
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(4)
    },
    "& .MuiFormHelperText-root": {
      marginInline: "0"
    }
  },
  name: {
    width: "40%",
    marginInlineEnd: theme.spacing(2)
  },
  email: {
    width: "60%"
  },
  uploadContainer: {
    display: "flex",
    marginBlockEnd: "32px",
    alignItems: "center"
  },
  uploadBtn: {
    marginInlineEnd: "16px"
  },
  messageReceived: {
    marginBlock: theme.spacing(7),
    alignItems: "center",
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignContent: "center"
  },
  nameAndMail: {
    display: "inline-flex",
    width: "100%"
  },
  contactForm: {
    display: "flex",
    width: "80%",
    marginInline: theme.spacing(8),
    paddingInline: theme.spacing(8),
    flexFlow: "column nowrap",
    alignSelf: "center"
  },
  uploadImageButton: {
    color: theme.palette.secondary.main
  },
  fileNameAndProgress: {
    width: "auto",
    display: "flex",
    flexGrow: 1,
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  input: {
    display: "none"
  }
}));

export default function ContactUs({ ...props }) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  // Ephemeral state
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [plainMessage, setPlainMessage] = useState("");
  const [validationMessages, setValidationMessages] = useState(null);

  const [uploading, setUploading] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [showThanksForContactingMsg, setShowThanksForContactingMsg] =
    useState(false);
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false);

  // Redux State
  const userDisplayName = useSelector((state) => state.user.auth.displayName);
  const userProfileIsSet = useSelector((state) => state.user.userProfileSet);
  const userEmail = useSelector((state) => state.user.auth.email);
  const userId = useSelector((state) => state.user.auth.uid);
  const courses = useSelector((state) => state.courses.courses);

  // variables

  // Bahavior

  // set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.contactUs",
        defaultMessage: "Contact us"
      })
    });

    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, [dispatch, intl]);

  // set default values for contact form
  useEffect(() => {
    if (userDisplayName) {
      setFullName(userDisplayName);
    }
  }, [userDisplayName]);

  useEffect(() => {
    if (userEmail) {
      setEmail(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    if (plainMessage.length > 0 && plainMessage.length < 10) {
      setValidationMessages({
        message: "Message must be at least 10 characters long."
      });
    } else if (message.length > 1000) {
      setValidationMessages({
        message: "Message cannot be more than 1000 characters."
      });
    } else {
      setValidationMessages({ message: "" });
    }
  }, [plainMessage]);

  function handleSubmit() {
    setSubmitButtonDisabled(true);
    if (validateForm()) {
      const eventId = Sentry.captureMessage("User Feedback");
      const userFeedback = {
        associatedEventId: eventId,
        name: fullName,
        email: email,
        message: message
      };

      Sentry.captureFeedback(userFeedback, { includeReplay: true });
      httpCallables
        .contactAlethea({
          fullName: fullName,
          email: email,
          content: message,
          attachment: downloadUrl,
          userId: userId,
          userCourses: courses.map((course) => course.id)
        })
        .then((response) => {
          if (response.data === "success") {
            if (userProfileIsSet) {
              dispatch(
                addSnackbar({
                  message: intl.formatMessage({
                    id: "contactUs.success",
                    defaultMessage:
                      "Your message was sent. Thank you for contacting Alethea"
                  })
                })
              );
              setSubmitButtonDisabled(false);

              navigate("/");
            } else {
              setShowThanksForContactingMsg(true);
            }
          } else {
            dispatch(
              addSnackbar({
                message: intl.formatMessage({
                  id: "contactUs.fail",
                  defaultMessage:
                    "There was an problem sending you message. Please try again"
                }),
                severity: "error"
              })
            );
            setSubmitButtonDisabled(false);
          }
        });
    } else {
      setSubmitButtonDisabled(false);
    }
  }

  function validateForm() {
    setValidationMessages(null);
    let isValid = true;

    let newValidationMessage = {};
    // Validate name
    if (!fullName || fullName === "") {
      newValidationMessage.fullName = "Please enter your full name";
      isValid = false;
    }

    // Validate email
    if (!email || email === "") {
      newValidationMessage.email = "Please enter an email";
      isValid = false;
    } else if (!validateEmail(email)) {
      newValidationMessage.email = "Please enter a valid email address";
      isValid = false;
    }

    // Validate massage
    if (!message || message === "") {
      newValidationMessage.message = "Please enter a message";
      isValid = false;
    }
    if (message.length > 0 && message.length < 10) {
      newValidationMessage.message =
        "Message must be at least 10 characters long.";
      isValid = false;
    } else if (message.length > 1000) {
      newValidationMessage.message =
        "Message cannot be more than 1000 characters.";
      isValid = false;
    }
    setValidationMessages(newValidationMessage);
    return isValid;
  }

  function validateEmail(userInput) {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        userInput
      )
    ) {
      return true;
    } else return false;
  }

  // Render

  const ThankYouMessage = () => {
    return (
      <Box className={classes.messageReceived}>
        <h2>Thank You for Contacting Alethea!</h2>
        <p>You can now refresh or close the window.</p>{" "}
        <Button
          variant="text"
          color="secondary"
          disableElevation
          onClick={() => {
            props.setShowContactUs(false);
            navigate("/");
          }}>
          {`(or you can just click here)`}
        </Button>
      </Box>
    );
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setUploading(true);
      setFileName(file.name);
      setUploadProgress(0);

      const storageRef = ref(storage, `/contactPangeaAttachments/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file, {
        contentType: file.type
      });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          setUploading(false);
          setUploadProgress(0);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setDownloadUrl(downloadURL);
          setUploading(false);
          setUploadProgress(100);
        }
      );
    } catch (err) {
      console.error("Upload error:", err);
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const renderUploadStatus = () => {
    if (!fileName) return null;

    return (
      <Box className={classes.fileNameAndProgress}>
        <Box>
          <span id="file-name">{truncateMiddle(fileName, 20)}</span>
        </Box>
        <Box>
          <CircularProgressWithLabel
            color="secondary"
            variant="determinate"
            value={uploadProgress}
            aria-label={`Upload progress: ${uploadProgress}%`}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.inner}>
      {showThanksForContactingMsg ? (
        <ThankYouMessage />
      ) : (
        <ScrollBox className={clsx(classes.mainView)}>
          <Box className={classes.contactForm}>
            <Box
              sx={{
                margin: "50px 0 40px 0",
                display: "flex",
                alignItems: "center",
                position: "relative"
              }}>
              {!userProfileIsSet && (
                <Box>
                  <IconButton
                    aria-label="go-back"
                    sx={{ position: "absolute", left: "-70px", top: "0" }}
                    size="large"
                    onClick={() => props.setShowContactUs(false)}>
                    <ArrowBackIcon
                      sx={{
                        color: "text.primary",
                        width: "36px",
                        height: "36px"
                      }}
                    />
                  </IconButton>
                </Box>
              )}
              <Typography variant="h3">Contact Alethea</Typography>
            </Box>
            <form autoComplete="off" className={classes.form}>
              <Box className={classes.nameAndMail}>
                <TextField
                  variant="outlined"
                  aria-label="contactUs-fullName-input"
                  id="fullName"
                  className={classes.name}
                  label="Full name"
                  value={fullName}
                  required
                  error={!!validationMessages?.fullName}
                  helperText={validationMessages?.fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />

                <TextField
                  variant="outlined"
                  aria-label="contactUs-email-input"
                  id="email"
                  className={classes.email}
                  label="E-mail"
                  type="email"
                  value={email}
                  disabled={userProfileIsSet}
                  required
                  error={!!validationMessages?.email}
                  helperText={validationMessages?.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <NewTextEditor
                onContentChange={(content) => {
                  setMessage(content.richText);
                  setPlainMessage(content.plainText);
                }}
                showWordCount={true}
                placeholder="Message"
                error={validationMessages?.message}
              />
              {userProfileIsSet && (
                <Box className={classes.uploadContainer}>
                  <input
                    accept=".docx, application/pdf, application/msword, image/*"
                    className={classes.input}
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    aria-describedby="file-upload-description"
                  />
                  <label htmlFor="file-upload">
                    <Button
                      disableElevation
                      variant="outlined"
                      color="secondary"
                      component="span"
                      className={classes.uploadImageButton}
                      aria-label="Upload file"
                      disabled={uploading}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          document.getElementById("file-upload").click();
                        }
                      }}>
                      Upload file
                    </Button>
                  </label>
                  {renderUploadStatus()}
                  <div id="file-upload-description" style={{ display: "none" }}>
                    Accepts .docx, .pdf, .msword, and image files
                  </div>
                </Box>
              )}
              {!userProfileIsSet && (
                <Box sx={{ marginBottom: "30px" }}>
                  <ReCAPTCHA
                    sitekey={getRecaptchaKey()}
                    onChange={() => {
                      setVerifiedRecaptcha(true);
                    }}
                  />
                </Box>
              )}
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleSubmit}
                disabled={
                  submitButtonDisabled ||
                  uploading ||
                  (!userProfileIsSet && !verifiedRecaptcha)
                }>
                Submit
              </Button>
            </form>
          </Box>
        </ScrollBox>
      )}
    </Box>
  );
}
