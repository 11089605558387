// MFASetup.jsx
import React, { useState, useEffect, useCallback } from "react";
import { multiFactor } from "firebase/auth";
import { auth } from "../../../firebase";
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Divider,
  CircularProgress
} from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import makeStyles from "@mui/styles/makeStyles";

// Import sub-components
import PhoneMFASetup from "./PhoneMFASetup";
import AuthenticatorMFASetup from "./AuthenticatorMFASetup";
import ReauthenticationForm from "./ReauthenticationForm";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto"
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  tabsContainer: {
    marginBottom: theme.spacing(3)
  },
  errorContainer: {
    marginTop: theme.spacing(3)
  }
}));

export default function MFASetup() {
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  // Component state
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [needsReauth, setNeedsReauth] = useState(false);

  // Check if user already has MFA enrolled and verify authentication method
  useEffect(() => {
    const checkMFAStatus = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate("/library");
          return;
        }

        // Check if the user's auth method supports MFA
        const providerId = user.providerData[0]?.providerId;
        const supportedProviders = [
          "password", // Email/password
          "phone", // Phone auth
          "google.com", // Google
          "facebook.com", // Facebook
          "github.com", // GitHub
          "twitter.com", // Twitter
          "microsoft.com", // Microsoft
          "apple.com" // Apple
        ];

        if (!supportedProviders.includes(providerId)) {
          setError(
            `Your current sign-in method (${providerId || "anonymous"}) doesn't support MFA. Please sign in with email/password or a social provider.`
          );
          return;
        }

        // Check if user has verified email (if using email/password)
        if (providerId === "password" && !user.emailVerified) {
          setError("Please verify your email address before setting up MFA.");
          return;
        }

        const mfaUser = multiFactor(user);
        if (mfaUser.enrolledFactors.length > 0) {
          // User already has MFA enrolled, redirect to dashboard
          navigate("/library");
        }
      } catch (error) {
        console.error("Error checking MFA status:", error);
        setError("Error checking MFA status: " + error.message);
      }
    };

    checkMFAStatus();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setError("");
  };

  const handleReauthSuccess = () => {
    setNeedsReauth(false);
  };

  useEffect(() => {
    console.log("Changed on Error of MFASetup");
  }, [setError]);

  useEffect(() => {
    console.log("Changed on NeedsReauth of MFASetup");
  }, [setNeedsReauth]);

  const handleMFAError = useCallback(
    (errorMessage) => {
      // Check if it's a re-authentication required error
      if (errorMessage && errorMessage.includes("requires-recent-login")) {
        setNeedsReauth(true);
      } else {
        setError(errorMessage);
      }
    },
    [setNeedsReauth, setError]
  );

  const handleMFASuccess = () => {
    // Navigate to dashboard after successful MFA setup
    navigate("/library");
  };

  // Render error section with troubleshooting tips
  const renderErrorSection = () => {
    if (!error) return null;

    return (
      <Box className={classes.errorContainer}>
        <Typography color="error" gutterBottom>
          {error}
        </Typography>

        {error.includes("unsupported-first-factor") && (
          <Box mt={2} p={2} bgcolor="#f5f5f5" borderRadius={1}>
            <Typography variant="subtitle2" gutterBottom>
              Troubleshooting:
            </Typography>
            <ul style={{ paddingLeft: "20px", margin: "8px 0" }}>
              <li>
                <Typography variant="body2">
                  Make sure you are using email/password, phone, or social
                  provider authentication
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  If using email/password, verify your email address first
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Make sure your Firebase project is on a paid plan (Blaze or
                  better)
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Try signing out and signing back in before attempting MFA
                  setup
                </Typography>
              </li>
            </ul>
          </Box>
        )}
      </Box>
    );
  };

  if (loading && !needsReauth) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper elevation={3} className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {intl.formatMessage({
          id: "mfa.setup.title",
          defaultMessage: "Enable Two-Factor Authentication"
        })}
      </Typography>

      {needsReauth ? (
        <ReauthenticationForm
          onSuccess={handleReauthSuccess}
          onError={handleMFAError}
          loading={loading}
          setLoading={setLoading}
        />
      ) : (
        <>
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage({
              id: "mfa.setup.description",
              defaultMessage:
                "As a privileged user, you need to set up two-factor authentication for enhanced security."
            })}
          </Typography>

          <Box className={classes.tabsContainer}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth">
              <Tab label="Phone" />
              <Tab label="Authenticator App" />
            </Tabs>
          </Box>

          <Divider />

          {/* Phone Authentication */}
          {tabValue === 0 && (
            <PhoneMFASetup
              onError={handleMFAError}
              onSuccess={handleMFASuccess}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {/* Authenticator App */}
          {tabValue === 1 && (
            <AuthenticatorMFASetup
              onError={handleMFAError}
              onSuccess={handleMFASuccess}
              loading={loading}
              setLoading={setLoading}
            />
          )}

          {renderErrorSection()}
        </>
      )}
    </Paper>
  );
}
