import React from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  DialogActions
} from "@mui/material";

export function OfflineDialog({ isOffline }) {
  return (
    <Dialog open={isOffline} onClose={() => {}}>
      <DialogTitle>Connection Error</DialogTitle>
      <DialogContent>
        <Typography>Alethea must have an internet connection,</Typography>
        <Typography>
          lost connection to server, this message will disappear when connection
          is resumed
        </Typography>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}
