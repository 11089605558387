import React, { useEffect } from "react";
import { logout } from "../../redux/userSlice";
import { auth } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PangeaSpinner from "../SharedComponents/PangeaSpinner";
import { captureException } from "../../utils/errorHandlers";
import { userAPI } from "../../api";
import { ACTIVITY_EVENT, IDLE_PERIOD } from "../../consts";
import { add } from "date-fns";
function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.firebase.auth.uid);

  useEffect(() => {
    const now = new Date();
    const shouldAwait = true;

    const clearOneTrustCookies = () => {
      const cookiesToClear = ["OptanonAlertBoxClosed", "OptanonConsent"];

      cookiesToClear.forEach((cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });
    };

    const logoutUser = async () => {
      try {
        await userAPI.log(
          {
            action_name: "ACTIVITY_EVENT",
            user_id: userId,
            created_at: now,
            ttl: add(now, { months: 1 }),
            payload: {
              type: ACTIVITY_EVENT.END,
              idle_period: IDLE_PERIOD
            }
          },
          shouldAwait
        );
        await auth.signOut();
        localStorage.removeItem("impersonate.originalUser");
        // avoid for now
        // clearOneTrustCookies();
        dispatch(logout());
        navigate("/");
      } catch (err) {
        captureException(err);
      }
    };

    logoutUser();
  }, [history, dispatch, userId]);

  return <PangeaSpinner />;
}

export default Logout;
