// Dependencies
import React from "react";

// Redux dependencies

// Components
import ScrollBox from "../SharedComponents/ScrollBox";

import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import ProfilePageForm from "./ProfilePageForm";
import ProfilePageAvatar from "./ProfilePageAvatar";
import { getStorage } from "firebase/storage";
import { useFirebaseApp, StorageProvider } from "reactfire";

//Styles
const useStyles = makeStyles((theme) => ({
  profileContainer: {
    paddingBlock: theme.spacing(9)
  },
  avatarSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatarImg: {
    marginBlockStart: theme.spacing(4),
    marginBlockEnd: theme.spacing(7.5),
    borderRadius: 100,
    width: 178
  },
  input: {
    display: "none"
  }
}));

export default function ProfilePage() {
  //Hooks
  const classes = useStyles();
  const reactFirebaseApp = useFirebaseApp();
  const storage = getStorage(reactFirebaseApp);

  //Ephemeral state

  //Redux state

  //Variables

  //Behavior

  // Render
  return (
    <ScrollBox>
      <Grid className={classes.profileContainer} container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <StorageProvider sdk={storage}>
            <ProfilePageAvatar />
          </StorageProvider>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <ProfilePageForm />
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </ScrollBox>
  );
}
