import React from "react";
import { Paper, Typography, Button } from "@mui/material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useIntl } from "react-intl";
import useGetTheme from "./hooks/useGetTheme";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  errorPageWrapper: {
    borderRadius: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  errorPageTitle: {
    marginBottom: theme.spacing(2)
  },
  errorPageBody: {
    marginBottom: theme.spacing(12)
  }
}));

export default function ErrorPage() {
  const classes = useStyles();
  const theme = useGetTheme({ alwase: "dark" });
  const intl = useIntl();
  const navigate = useNavigate();

  function cacheReloadCount() {
    const previousCount = Number(localStorage.getItem("reloadCount")) || 0;
    if (previousCount === 0) {
      localStorage.setItem("reloadCount", previousCount + 1);
    } else if (previousCount > 0) {
      navigate("/library");
      localStorage.setItem("reloadCount", 0);
    }
    window.location.reload();
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Paper
          className={classes.errorPageWrapper}
          data-testid="error-page-wrapper"
          component="main"
          role="alert"
          aria-labelledby="error-title"
          aria-describedby="error-description">
          <Typography
            variant="h1"
            component="h1"
            className={classes.errorPageTitle}
            id="error-title"
            sx={{
              fontSize: theme.typography.h2.fontSize, // Maintain visual sizing while using semantic h1
              fontWeight: theme.typography.h2.fontWeight
            }}>
            {intl.formatMessage({
              id: "errorPage.title",
              defaultMessage: "oops, something went wrong"
            })}
          </Typography>
          <Typography
            className={classes.errorPageBody}
            id="error-description"
            component="p">
            {intl.formatMessage({
              id: "errorPage.body",
              defaultMessage:
                "Try refreshing, and if this problem persists please email us at support.alethea@clarivate.com"
            })}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => {
              cacheReloadCount();
            }}
            aria-label={intl.formatMessage({
              id: "errorPage.btnAriaLabel",
              defaultMessage: "Reload page and try again"
            })}>
            {intl.formatMessage({
              id: "errorPage.btnText",
              defaultMessage: "Try Again"
            })}
          </Button>
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
