const paths = {
  "/tasks": "Assignments",
  "/library": "Library",
  "/tasks/new": "Create a new assignment",
  "/tasks/edit": "Edit assignment",
  "/task": "Assignment",
  "/reports/stats": "Assignment Statistics",
  "/reports/student": "Student analytics",
  "/reader": "Reader",
  "/contactUs": "Contact us",
  "/notifications": "Notifications",
  "/profile": "My Profile",
  "/logout": "Logout",
  "/reports/courseExternal": "Course Report",
  "/admin": "System Console"
};

export default paths;
